










































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { IItem } from '@/components/ui/types';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxInventoryTemplateRepository, {
  IBoxInventoryTemplate,
  IBoxInventoryTemplateItem,
  IAmountInventoryItem,
} from '@/services/Repositories/boxInventoryTemplate/types';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';
import { IRebox } from '@/store/modules/reboxes/types';
import IInventoryItemsRepository from '@/services/Repositories/inventoryItemRepository/types';
import IBoxesRepository from '@/services/Repositories/Boxes/types';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import ItemCounter from '@/components/ui/ItemCounter.vue';
import ReboxDetails from '@/components/ui/ReboxDetails.vue';
import LocalStorage from '@/services/localStorage';

const Factory = new RepositoryFactory();

interface ICustomTemplate {
  boxInventoryTemplateItems: Array<void>;
  id: string;
  name: string;
}

export default Vue.extend({
  name: 'reboxId',
  components: {
    ItemCounter,
    ReboxDetails,
    TheFabButton,
  },
  data() {
    return {
      templates: [] as Array<ICustomTemplate | IBoxInventoryTemplate>,
      templateItems: [] as Array<IAmountInventoryItem>,
      amountChanged: false,
      updatingRebox: false,
      templateId: '',
    };
  },
  computed: {
    ...mapState('globals', ['loader']),
    ...mapState('reboxes', ['reboxes']),
    ...mapState('lastEdited', ['editedOrders']),
    boxInventoryTemplates(): IBoxInventoryTemplateRepository {
      return Factory.get('boxInventoryTemplates') as IBoxInventoryTemplateRepository;
    },
    inventoryItemsRepository(): IInventoryItemsRepository {
      return Factory.get('inventoryItems') as IInventoryItemsRepository;
    },
    boxShippings(): IBoxShippingsRepository {
      return Factory.get('boxShippings') as IBoxShippingsRepository;
    },
    boxesRepository(): IBoxesRepository {
      return Factory.get('boxes') as IBoxesRepository;
    },
    reboxId(): string {
      const {
        params: { reboxId },
      } = (this as any).$route;
      return reboxId;
    },
    orderId(): string {
      const {
        params: { orderId },
      } = (this as any).$route;
      return orderId;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_ALERT_TITLE', 'SET_ALERT_TEXT', 'SET_PAGE']),
    ...mapMutations('reboxes', ['ADD_REBOX', 'UPDATE_REBOX']),
    updateItem(item: IItem): void {
      const indexOfItem = this.templateItems.findIndex(
        ({ inventoryItem: { id } }) => id === item.id,
      );
      const itemTemplate = this.templateItems.find(({ inventoryItem: { id } }) => id === item.id);
      if (item.amount >= 0 && !Number.isNaN(item.amount)) {
        Vue.set(this.templateItems[indexOfItem], 'amount', item.amount);
      } else {
        Vue.set(this.templateItems, indexOfItem, { ...itemTemplate, amount: item.amount });
      }
    },
    async createLabel() {
      try {
        const createdRebox = {
          template: this.templateItems,
          reboxNr: this.reboxId,
          templateNr: this.templateId,
          printed: false,
        };
        if (this.updatingRebox) {
          this.UPDATE_REBOX(createdRebox);
        } else {
          this.ADD_REBOX(createdRebox);
        }
        // TODO
        // sent to reboxes state
        // rebox nr, selected template, seleected inventries items
        this.$router.push({
          name: 'orderRebxoes',
          params: { orderId: this.orderId },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getTemplates() {
      const customTemplate = {
        name: 'Custom template',
        id: 'custom-template',
        boxInventoryTemplateItems: [],
      };
      const {
        data: { 'hydra:member': templates },
      } = await this.boxInventoryTemplates.get();
      this.templates = [customTemplate, ...templates];
    },
    async getInventoryItem(items: IBoxInventoryTemplateItem[]) {
      const changed: string[] = [];
      if (items.length) {
        const [template] = items;
        const { boxInventoryTemplate } = template;
        this.templateId = boxInventoryTemplate.split('/').pop() || '';
      } else {
        this.templateId = 'custom-template';
      }
      items.forEach(async ({ inventoryItem, amount }) => {
        const id = typeof inventoryItem === 'string' ? inventoryItem : '';
        const itemId = id.split('/').pop() || '';
        changed.push(itemId);
        const index = this.templateItems.findIndex(
          ({ inventoryItem: { id: templateId } }) => templateId === itemId,
        );
        const restItem = this.templateItems.find(
          ({ inventoryItem: { id: templateId } }) => templateId === itemId,
        );
        Vue.set(this.templateItems, index, { ...restItem, amount });
        this.templateItems.forEach((item, _index) => {
          const {
            inventoryItem: { id: _id },
          } = item;
          if (!changed.includes(_id)) {
            Vue.set(this.templateItems, _index, { ...item, amount: '0' });
          }
        });
      });
    },
    async getInventoryItems(): Promise<void> {
      const {
        data: { 'hydra:member': items },
      } = await this.inventoryItemsRepository.get();
      const newItems = items.map((item) => {
        const itemWithAmount = { amount: '0', inventoryItem: item };
        return itemWithAmount;
      });
      this.templateItems = newItems;
    },
    initComponent(): void {
      const updatingRebox: IRebox = this.reboxes.find(
        ({ reboxNr }: IRebox) => reboxNr === this.reboxId,
      );
      if (updatingRebox) {
        const { template, templateNr } = updatingRebox;
        this.updatingRebox = true;
        this.templateItems = template;
        this.templateId = templateNr;
      }
    },
    getTemplateId() {
      const templateId = LocalStorage.getItem('savedTemplate');
      this.templateId = typeof templateId === 'string' ? JSON.parse(templateId) : 'custom-template';
    },
  },
  async created() {
    this.$headerService.setHeaderTitle('header.createRebox');
    this.initComponent();
    if (!this.updatingRebox) {
      await this.getInventoryItems();
      this.SET_PAGE('allOrders');
      this.$headerService.setPreviousPage({ name: 'findRebox', params: { orderId: this.orderId } });
    } else {
      const { icons, actions } = this.$headerService.getIconsAndActions();
      this.$headerService.config({
        title: 'header.updateRebox',
        icons: { icon: icons.back, action: actions.goBack },
        page: { name: 'orderReboxes', params: { orderId: this.orderId } },
      });
    }
    await this.getTemplates();
    this.getTemplateId();
  },
  watch: {
    templateItems: {
      handler(val) {
        const { icons, actions } = this.$headerService.getIconsAndActions();
        const amountArray = Object.values(val)
          .map(({ amount }: any) => parseInt(amount, 10))
          .filter((amount) => amount > 0);
        if (amountArray.length && !this.updatingRebox) {
          this.$headerService.config({
            icons: { icon: icons.cancel, action: actions.close },
          });
        } else {
          this.$headerService.config({
            icons: { icon: icons.back, action: actions.goBack },
            page: { name: 'orderRebxoes', params: { orderId: this.orderId } },
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
